import * as THREE from "three";
import { useMemo, useState, useRef, useEffect } from "react";
import { createPortal, useFrame } from "@react-three/fiber";
import { useFBO } from "@react-three/drei";
import "./shaders/simulationMaterial";
import "./shaders/dofPointsMaterial";

// { speed, fov, aperture, focus, curl, size = 512, ...props }
export function Particles() {
  const simRef = useRef();
  const renderRef = useRef();
  const [boolean, setBoolean] = useState(true);
  const [focusState, setFocusState] = useState(4.5);

  //     focus: { value: 4.5, min: 4.5, max: 7, step: 0.01 },
  //     speed: { value: 0.1, min: 0.1, max: 100, step: 0.1 },
  //     aperture: { value: 4.3, min: 1, max: 5.6, step: 0.1 },
  //     fov: { value: 112, min: 0, max: 200 },
  //     curl: { value: 0.25, min: 0.01, max: 0.5, step: 0.01 }

  const [size, setSize] = useState(512);
  const [speed, setSpeed] = useState(0.1);
  const [fov, setFov] = useState(112);
  const [aperture, setAperture] = useState(4.3);
  const [focus, setFocus] = useState(4.5);
  const [curl, setCurl] = useState(0.25);

  // Set up FBO
  const [scene] = useState(() => new THREE.Scene());
  const [camera] = useState(
    () => new THREE.OrthographicCamera(-1, 1, 1, -1, 1 / Math.pow(2, 53), 1)
  );
  const [positions] = useState(
    () =>
      new Float32Array([
        -1, -1, 0, 1, -1, 0, 1, 1, 0, -1, -1, 0, 1, 1, 0, -1, 1, 0,
      ])
  );
  const [uvs] = useState(
    () => new Float32Array([0, 1, 1, 1, 1, 0, 0, 1, 1, 0, 0, 0])
  );
  const target = useFBO(size, size, {
    minFilter: THREE.NearestFilter,
    magFilter: THREE.NearestFilter,
    format: THREE.RGBAFormat,
    type: THREE.FloatType,
  });

  // Normalize points
  const particles = useMemo(() => {
    const length = size * size;
    const particles = new Float32Array(length * 3);
    for (let i = 0; i < length; i++) {
      let i3 = i * 3;
      particles[i3 + 0] = (i % size) / size;
      particles[i3 + 1] = i / size / size;
    }
    return particles;
  }, [size]);

  useEffect(() => {
    const interval = setInterval(function () {
      if (boolean) {
        if (focusState >= 6.8) {
          setBoolean(!boolean);
        }
        setFocusState(focusState + 0.01);
      } else {
        if (focusState <= 4.5) {
          setBoolean(!boolean);
        }
        setFocusState(focusState - 0.01);
      }
    }, 10);
    return () => clearInterval(interval);
  }, [focusState, boolean]);

  // Update FBO and pointcloud every frame
  useFrame((state) => {
    state.gl.setRenderTarget(target);
    state.gl.clear();
    state.gl.render(scene, camera);
    state.gl.setRenderTarget(null);
    renderRef.current.uniforms.positions.value = target.texture;
    renderRef.current.uniforms.uTime.value = state.clock.elapsedTime;
    renderRef.current.uniforms.uFocus.value = THREE.MathUtils.lerp(
      renderRef.current.uniforms.uFocus.value,
      focusState,
      0.1
    );
    renderRef.current.uniforms.uFov.value = THREE.MathUtils.lerp(
      renderRef.current.uniforms.uFov.value,
      fov,
      0.1
    );
    renderRef.current.uniforms.uBlur.value = THREE.MathUtils.lerp(
      renderRef.current.uniforms.uBlur.value,
      (5.6 - aperture) * 9,
      0.1
    );
    simRef.current.uniforms.uTime.value = state.clock.elapsedTime * speed;
    simRef.current.uniforms.uCurlFreq.value = THREE.MathUtils.lerp(
      simRef.current.uniforms.uCurlFreq.value,
      curl,
      0.1
    );
  });
  return (
    <>
      {/* Simulation goes into a FBO/Off-buffer */}
      {createPortal(
        <mesh>
          <simulationMaterial ref={simRef} />
          <bufferGeometry>
            <bufferAttribute
              attach="attributes-position"
              count={positions.length / 3}
              array={positions}
              itemSize={3}
            />
            <bufferAttribute
              attach="attributes-uv"
              count={uvs.length / 2}
              array={uvs}
              itemSize={2}
            />
          </bufferGeometry>
        </mesh>,
        scene
      )}
      {/* The result of which is forwarded into a pointcloud via data-texture */}
      <points>
        <dofPointsMaterial ref={renderRef} />
        <bufferGeometry>
          <bufferAttribute
            attach="attributes-position"
            count={particles.length / 3}
            array={particles}
            itemSize={3}
          />
        </bufferGeometry>
      </points>
    </>
  );
}
