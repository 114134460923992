import * as THREE from "three";
import { render, events, extend } from "@react-three/fiber";
import "./styles.css";
import App from "./App";

extend(THREE);

window.addEventListener("resize", () =>
  render(<App />, document.querySelector("canvas"))
);

window.dispatchEvent(new Event("resize"));
